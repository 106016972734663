module.exports = {
  es: {
    locale: "es",
    path: "es",
    label: "Español",
    abbr: "Es",
    translated_routes: {
      "/es/": "/es/",
      "/es/about/": "/es/acerca/",
      "/es/projects/": "/es/proyectos/",
      "/es/contact/": "/es/contacto/",
      "/es/newsletter/": "/es/lista-de-correo/",
      "/es/privacy/": "/es/privacidad/"
    },
  },
  en: {
    locale: "en",
    path: "/",
    label: "English",
    abbr: "En",
    default: true,
  },
};
