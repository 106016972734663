import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import Helmet from "react-helmet";
import { useI18next } from "gatsby-plugin-react-i18next";
import { routes } from "../../i18n/locales";

function SEO({ description, lang, meta, keywords, title, image }) {
  const { site } = useStaticQuery(graphql`
    query DefaultSEOQuery {
      site {
        siteMetadata {
          title
          description
          author
          siteUrl
        }
      }
    }
  `);

  const metaDescription = description || site.siteMetadata.description;
  const c = useI18next();
  let r = routes;
  let localizedTo, locizedTitle;
  if (c.language === `en`) {
    localizedTo = r["es"].translated_routes["/es" + c.path];
    locizedTitle = "Diego Valle-Jones";
  }
  if (c.language === "es") {
    localizedTo = c.path.replace("es", "");
    locizedTitle = "Diego Valle Jones";
  }
  return (
    <Helmet
      htmlAttributes={{
        lang: c.language,
      }}
      title={title === "Home" ? locizedTitle : title}
      titleTemplate={title === "Home" ? locizedTitle : `%s | ${locizedTitle}`}
    >
      <meta name={`description`} content={metaDescription} />
      <meta
        property={`og:title`}
        content={title === "Home" ? locizedTitle : title}
      />
      <meta property={`og:description`} content={metaDescription} />
      <meta property={`og:type`} content={`website`} />
      <meta name={`twitter:card`} content={`summary`} />
      <meta name={`twitter:creator`} content={site.siteMetadata.author} />
      <meta
        name={`twitter:title`}
        content={title === "Home" ? locizedTitle : title}
      />
      <meta name={`twitter:description`} content={metaDescription} />
      <meta
        name={`og:image`}
        content={image ? site.siteMetadata.siteUrl + image : ""}
      />
      <meta
        name={`image`}
        content={image ? site.siteMetadata.siteUrl + image : ""}
      />
      <meta name={`og:image:height`} content={630} />
      <meta name={`og:image:width`} content={200} />

      <meta property="og:site_name" content={"Diego Valle-Jones"} />
      <link
        rel="alternate"
        hreflang={c.language === "es" ? "en" : "es"}
        href={"https://www.diegovalle.net" + localizedTo}
      />
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "WebSite",
          name: "Diego Valle-Jones",
          inLanguage: c.language === "es" ? "es" : "en",
          url:
            c.language === "es"
              ? "https://www.diegovalle.net/es/"
              : "https//www.diegovalle.net/",
          author: [
            {
              "@type": "Person",
              "@id": "https://www.diegovalle.net/#Person",
              name: "Diego Valle-Jones",
              givenName: "Diego",
              familyName: "Valle-Jones",
              sameAs: [
                "https://twitter.com/diegovalle",
                "https://github.com/diegovalle",
                "https://facebook.com/diegovalle",
                "https://www.linkedin.com/in/diegovalle",
                "",
                "",
              ],
              url: "https://www.diegovalle.net",
            },
          ],
        })}
      </script>
    </Helmet>
  );
}

SEO.defaultProps = {
  keywords: [],
  meta: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  lang: PropTypes.string,
  meta: PropTypes.array,
  title: PropTypes.string.isRequired,
};

export default SEO;
