import PropTypes from 'prop-types';
import React from 'react';
import {FaGithub, FaTwitter} from 'react-icons/fa';
import {Helmet} from 'react-helmet';

import Header from './header';

function Layout({children}) {
  return (
    <div className="flex flex-col font-sans min-h-screen text-gray-900">
      <Helmet>
        <link href="https://www.google.com" rel="preconnect" />
        <link href="https://www.gstatic.com" rel="preconnect" />
      </Helmet>
      <Header />

      {children}

      <footer className="bg-gray-900">
        <nav className="flex justify-between max-w-4xl mx-auto p-4 md:p-8 text-sm">

          <a
            aria-label="twitter"
            className="font-bold no-underline text-white"
            href="https://twitter.com/diegovalle"
          >
            <FaTwitter size={32} />
          </a>

          <a
            aria-label="github"
            className="font-bold no-underline text-white"
            href="https://github.com/diegovalle"
          >

            <FaGithub size={32} />
          </a>

        </nav>
      </footer>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
