import React, { useContext } from "react";
import { Link } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
//import { I18nextContext } from "./i18nextContext";

import { routes } from "../../i18n/locales";

export const LocLink = ({ language, children, to, prefetch, ...props }) => {
  const c = useI18next();
  let r = routes;

  const localizedTo =
    c.language == c.defaultLanguage
      ? to
      : r[c.language].translated_routes['/' + c.language + to];
  if (typeof localizedTo ===  "undefined")
    throw new Error("❌ Missing entry in i18n/locales/routes");

  if (!prefetch) {
    return (
      <a href={localizedTo} {...props}>
        {children}
      </a>
    );
  }
  return (
    <Link to={localizedTo} {...props}>
      {children}
    </Link>
  );
};

LocLink.defaultProps = {
  prefetch: true,
};

export default LocLink;
