import { graphql, useStaticQuery } from "gatsby";
import React, { useState } from "react";
import { Link, useI18next } from "gatsby-plugin-react-i18next";
import { LocLink } from "./LocLink";

import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import Language from "../components/Language";

function Header() {
  const { t } = useTranslation();
  const [isExpanded, toggleExpansion] = useState(false);
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <header className="bg-gray-900">
      <div className="flex flex-wrap items-center justify-between max-w-6xl mx-auto p-4 md:p-5">
        <LocLink className="flex items-center no-underline text-white" to="/">
          <svg
            className="fill-current h-8 mr-2 w-8"
            height="54"
            viewBox="0 0 54 54"
            width="54"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g transform="translate(-101.23536,-98.239847)">
              <path
                style={{ fill: "white" }}
                d="m 117.15706,150.50575 c -3.59316,-2.0654 -6.43731,-4.50248 -8.55326,-7.32912 -3.18205,-4.25087 -4.20141,-9.09024 -3.13854,-14.9004 2.77297,-15.15799 17.72655,-26.58067 37.90497,-28.954548 2.7748,-0.326473 7.59153,-0.248886 7.96572,0.128429 0.43862,0.44221 -1.11478,8.553329 -2.83368,14.797289 -1.21803,4.42483 -2.1517,6.36006 -3.74299,7.75824 -1.73231,1.52213 -3.7682,2.30673 -9.57087,3.68838 -6.20114,1.47646 -9.05259,2.39879 -12.05879,3.90045 -4.03183,2.01404 -6.04519,3.96648 -7.949,7.7086 l -1.19017,2.33944 v 3.08624 c 0,3.75492 0.30597,4.45502 3.04849,6.97055 0.99809,0.91556 1.76831,1.66437 1.71153,1.66403 -0.0562,-2.8e-4 -0.77379,-0.386 -1.59341,-0.85724 z"
                id="path1422"
              />
            </g>
          </svg>
          <h2
            className="font-bold text-2xl tracking-tight"
            style={{ fontFamily: '"Alegreya Sans",sans-serif' }}
          >
            <Trans>Diego Valle-Jones</Trans>
          </h2>
        </LocLink>

        <button
          className="block md:hidden border border-white flex items-center px-3 py-2 rounded text-white"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>

        <nav
          className={`${
            isExpanded ? `block` : `hidden`
          } md:block md:flex md:items-center w-full md:w-auto`}
        >
          {[
            {
              route: `/`,
              title: t(`Home`),
            },
          ].map((link) => (
            <LocLink
              className="block md:inline-block mt-4 md:mt-0 md:ml-6 no-underline text-white"
              key={link.title}
              to={link.route}
              activeClassName="block md:inline-block mt-4 md:mt-0 md:ml-6 text-white border-b-2 lg:border-b-2 xl:border-b-2 sm:border-b-0 md:border-b-0 border-white"
            >
              {link.title}
            </LocLink>
          ))}
          <a
            rel="me"
            href="https://blog.diegovalle.net"
            className="block md:inline-block mt-4 md:mt-0 md:ml-6 no-underline text-white"
          >
            Blog
          </a>
          {[
            {
              route: `/projects/`,
              title: t(`Projects`),
            },
            {
              route: `/about/`,
              title: t(`About`),
            },
            {
              route: `/contact/`,
              title: t(`Contact`),
            },
            {
              route: `/newsletter/`,
              title: t(`Newsletter`),
            },
          ].map((link) => (
            <LocLink
              className="block md:inline-block mt-4 md:mt-0 md:ml-6 no-underline text-white"
              key={link.title}
              to={link.route}
              activeClassName="block md:inline-block mt-4 md:mt-0 md:ml-6 text-white border-b-2 lg:border-b-2 xl:border-b-2 sm:border-b-0 md:border-b-0 border-white"
            >
              {link.title}
            </LocLink>
          ))}
          <div className="navbar-item">
            <Language className="flex md:flex mt-4 md:mt-0 md:ml-6 no-underline text-white" />
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;
